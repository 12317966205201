<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>사용자관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 1225px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<!-- 사용자ID -->
						<div class="col-md-3">
							<div class="form-group" style="margin-left: -40px">
								<label>{{ detailsFieldMap.userId }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									style="width: 190px"
									:placeholder="detailsFieldMap.userId"
									v-model="searchVM.userId"
									:maxlength="maxLength.userId"
									@keyup="userIdDataChk(userId, $event)"
								/>
							</div>
						</div>
						<!-- 사용자명 -->
						<div class="col-md-3">
							<div class="form-group">
								<label>{{ detailsFieldMap.userNm }}</label>
								<input
									type="text"
									class="form-control"
									style="width: 170px"
									:placeholder="detailsFieldMap.userNm"
									v-model="searchVM.userNm"
									:maxlength="maxLength.userNm"
								/>
							</div>
						</div>
						<!-- 현장명 -->
						<div class="col-md-3" style="width: 298px; margin-left: 2px">
							<div class="form-group">
								<label>{{ detailsFieldMap.siteNm }}</label>
								<select2 v-model="searchVM.siteNm" :disabled="isSiteDisabled">
									<option value="">전체</option>
									<option v-for="(row, index) in detailsOptions.siteOptions" :key="index" :value="row.siteNm">{{ row.siteNm }}</option>
								</select2>
							</div>
						</div>
						<!-- 업체명 -->
						<div class="col-md-3">
							<div class="form-group" style="margin-right: -12px">
								<label style="padding-left: 2px; width: 90px">{{ detailsFieldMap.companyNm }}</label>
								<select2 style="width: 65%" v-model="searchVM.companyId">
									<option value="">전체</option>
									<option v-for="(row, index) in detailsOptions.companyOptions" :key="index" :value="row.value">{{ row.text }}</option>
								</select2>
							</div>
						</div>
						<!-- 역할 -->
						<div class="col-md-3">
							<div class="form-group">
								<label style="padding-left: 2px">{{ detailsFieldMap.userRoleCd }}</label>
								<select2 v-model="searchVM.userRoleCd">
									<option value="">전체</option>
									<option v-for="(row, index) in detailsOptions.userRoleCdOptions" :key="index" :value="row.cd">{{ row.cdNm }}({{ row.cd }})</option>
								</select2>
							</div>
						</div>
						<!-- 휴대폰 번호 -->
						<div class="col-md-3">
							<div class="form-group">
								<label>{{ detailsFieldMap.hpNo }}</label>
								<input
									type="text"
									class="form-control"
									style="width: 172px"
									:placeholder="detailsFieldMap.hpNo"
									v-model="searchVM.hpNo"
									:maxlength="maxLength.hpNo"
									@keyup="hpNoDataChk('hpNo', $event)"
								/>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li @click="pannelHidden"><a data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								사용자관리 정보
							</legend>
							<div class="col-md-6">
								<!-- 역할(추가시) -->
								<div v-if="isCreateMode">
									<jarvis-field :label="detailsFieldMap.userRoleCd" field="detailsItem.userRoleCd" required="true">
										<select2
											v-model="detailsItem.userRoleCd"
											v-validate="validationRule.detailsItem.userRoleCd"
											data-vv-name="detailsItem.userRoleCd"
										>
											<option :value="null">선택</option>
											<option v-for="(row, index) in detailsOptions.userRoleCdOptions" :key="index" :value="row.cd">
												{{ row.cdNm }}({{ row.cd }})
											</option>
										</select2>
									</jarvis-field>
								</div>
								<!-- 역할(수정시)- 역할이 본사관리자일 때와 아닐 때를 구분. 본사관리자는 수정 불가. 다른 역할은 본사관리자로 수정 불가 -->
								<div v-if="isEditMode">
									<div v-if="detailsItem.userRoleCd == '00'">
										<jarvis-field
											:label="detailsFieldMap.userRoleCd"
											v-model="detailsOptions.userRoleCdOptions[0].cdNm"
											:disabled="true"
										></jarvis-field>
									</div>
									<div v-if="detailsItem.userRoleCd != '00'">
										<jarvis-field :label="detailsFieldMap.userRoleCd" field="detailsItem.userRoleCd" required="true">
											<select2 v-model="detailsItem.userRoleCd" data-vv-name="detailsItem.userRoleCd">
												<option :value="null">선택</option>
												<option v-for="(row, index) in detailsOptionsWithoutManager" :key="index" :value="row.cd">
													{{ row.cdNm }}({{ row.cd }})
												</option>
											</select2>
										</jarvis-field>
									</div>
								</div>
								<div id="userIdField" class="col-lg-9" style="margin-left: 24px">
									<jarvis-field
										:label="detailsFieldMap.userId"
										id="firstInputCreate"
										required="true"
										:maxLength="maxLength.userId"
										field="detailsItem.userId"
										v-model="detailsItem.userId"
										data-vv-name="detailsItem.userId"
										:disabled="!isCreateMode"
										@keyup="checkCurUserId"
										v-validate="validationRule.detailsItem.userId"
									></jarvis-field>
								</div>
								<button v-if="isCreateMode" id="duplicationCheckBtn" type="button" class="btn btn-primary" @click="duplicateUserIdCheck">
									중복체크
								</button>
								<!-- 사용자명 -->
								<jarvis-field
									:label="detailsFieldMap.userNm"
									id="firstInputEdit"
									required="true"
									:maxLength="maxLength.userNm"
									field="detailsItem.userNm"
									v-model="detailsItem.userNm"
									data-vv-name="detailsItem.userNm"
									v-validate="validationRule.detailsItem.userNm"
								></jarvis-field>
								<!-- 비밀번호 변경, 수정모드만 표시 -->
								<div class="form-group" v-if="isEditMode">
									<label class="col-lg-3 control-label" style="padding-right: 10px">{{ detailsFieldMap.isChangePwd }}</label>
									<div class="col-lg-9">
										<div class="checkbox">
											<label>
												<input
													type="checkbox"
													v-model="detailsItem.isChangePwd"
													style="transform: scale(1.5); margin-left: 5px"
													@change="checkChangePwd"
												/>
											</label>
										</div>
									</div>
								</div>
								<!-- 비밀번호 -->
								<jarvis-field
									:label="detailsFieldMap.pwd"
									required="true"
									v-if="isCreateMode || detailsItem.isChangePwd"
									field="detailsItem.pwd"
									v-model="detailsItem.pwd"
									type="password"
									data-vv-name="detailsItem.pwd"
									v-validate="validationRule.detailsItem.pwd"
									:maxLength="maxLength.pwd"
								></jarvis-field>
								<!-- 현장ID -->
								<div class="row itemWrapper" :class="errorSiteId" v-show="detailsItem.isSite">
									<label class="col-lg-3 title" style="margin: 0px 0px 0px 4px; padding: 10px">
										{{ detailsFieldMap.siteId }}:
										<span class="required">*</span>
									</label>
									<div class="col-lg-9" :class="errorSiteId" style="width: 370px; margin-right: -10px">
										<div class="select-container">
											<select2 v-model="detailsItem.siteId" @blur="checkSiteId(detailsItem.siteId)" @input="checkSiteId(detailsItem.siteId)">
												<option :value="null">선택</option>
												<option v-for="(row, index) in detailsOptions.siteOptions" :key="index" :value="row.siteId">
													{{ row.siteNm }}({{ row.siteId }})
												</option>
											</select2>
										</div>
										<label class="msg"></label>
									</div>
								</div>
								<!-- 업체ID -->
								<jarvis-field :label="detailsFieldMap.companyId" :required="true" field="detailsItem.companyId">
									<select2
										v-model="detailsItem.companyId"
										:options="detailsOptions.companyOptions"
										data-vv-name="detailsItem.companyId"
										v-validate="validationRule.detailsItem.companyId"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 핸드폰 어플리케이션 로그인 로직 상 변경 불가하도록 해둠. -->
								<jarvis-field
									type="text"
									:label="detailsFieldMap.hpNo"
									required="true"
									field="detailsItem.hpNo"
									v-model="detailsItem.hpNo"
									data-vv-name="detailsItem.hpNo"
									:disabled="!isCreateMode"
									v-validate="validationRule.detailsItem.hpNo"
									:maxLength="maxLength.hpNo"
								></jarvis-field>
								<!-- 휴대폰플랫폼 -->
								<!-- jarvis-field 이용시 버그가 생겨 임의로 생성하여 사용하였다. -->
								<div class="row itemWrapper">
									<label class="col-lg-3 title" style="margin: 0px 0px 0px 4px; padding: 10px">{{ detailsFieldMap.userMoblPlatfm }}:</label>
									<div class="col-lg-9" style="width: 370px; margin-right: -10px">
										<select2 v-model="detailsItem.userMoblPlatfm">
											<option :value="null">선택</option>
											<option v-for="(row, index) in detailsOptions.userMoblPlatfmOptions" :key="index" :value="row.cd">
												{{ row.cdNm }}({{ row.cd }})
											</option>
										</select2>
									</div>
								</div>
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>

							<div class="col-md-6" style="padding-left: 70px">
								<!-- 연결된 권한, 체크박스 목록 -->
								<div class="from-group">
									<label class="col-lg-3 control-label text-semibold" style="width: 120px">연결된 권한그룹</label>
									<div class="col-lg-9" style="text-align: left; width: 250px">
										<div class="checkbox" v-for="item in pageParam.roleGroupCodeOptions" :key="item.text">
											<label>
												<input type="checkbox" v-model="detailsItem.roleGrpCds" :value="item.value" />
												{{ item.value }} ({{ item.text }})
											</label>
										</div>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @mousedown="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @mousedown="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
const userApi = apiIndex.user;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		userRoleCd: 'required',
		userId: 'max:30|userIdCheck|required',
		userNm: 'max:50|required',
		companyId: 'required',
		hpNo: 'required|mobileNoNumPattern|max:11|',
		siteId: 'required',
	},
	edit: {
		//수정
	},
	create: {
		//생성
		pwd: 'required|min:4|max:20',
	},
};
const maxLength = {
	userId: '30',
	userNm: '50',
	pwd: '20',
	hpNo: '11',
};
_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		Select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			excelApi: userApi.exlUser,
			pageListApi: userApi.inqUser,
			detailsApi: userApi.inqOneUserWithRoleGroup,
			inqCheckCompanyManagerApi: userApi.inqCheckCompanyManager,
			updUserApi: userApi.updUser,
			delUserApi: userApi.delUser,
			insUserApi: userApi.insUser,
			inqCheckUserIdApi: userApi.inqCheckUserId,
			inqCheckHpNoApi: userApi.inqCheckHpNo,
		},
		//검색 VM
		searchVM: {
			userId: '',
			userNm: '',
			hpNo: '',
			siteNm: '',
			companyNm: '',
			userRoleCd: '',
		},
		//적용된 검색조건
		searchState: {},
		//검색VM-Select옵션
		searchOptions: {
			delYn: '',
		},
		gridColumns: [
			{ field: 'userId', title: '사용자ID', width: '10%' },
			{ field: 'userNm', title: '사용자명', width: '10%' },
			{ field: 'siteNm', title: '현장명', width: '15%' },
			{ field: 'companyNm', title: '업체명', width: '15%' },
			{ field: 'userRoleCd', title: '역할', width: '10%', dataFormat: 'optionMap' },
			{ field: 'hpNo', title: '연락처', width: '10%', dataFormat: 'hyphenPhone', align: 'center' },
			{ field: 'delYn', title: '사용여부', width: '5%', align: 'center', dataFormat: 'optionMap' },
			{ field: 'regDtm', title: '등록일', width: '10%', align: 'center', dataFormat: 'YYYY-MM-DD' },
			{ field: 'updDtm', title: '수정일', width: '10%', align: 'center', dataFormat: 'YYYY-MM-DD' },
		],
		//상세 select options
		detailsOptions: {},
		//상세필드명맵
		detailsFieldMap: {
			userId: '사용자ID',
			companyId: '업체ID',
			companyNm: '업체명',
			hpNo: '휴대폰번호',
			pwd: '비밀번호',
			isChangePwd: '비밀번호변경',
			userNm: '사용자명',
			userRoleCd: '역할',
			userMoblPlatfm: '휴대폰플랫폼',
			userMoblTokn: '토큰',
			siteId: '현장ID',
			siteNm: '현장명',
			delYn: '삭제여부',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '수정일',
			updUser: '수정자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {
			userId: '',
			companyId: '',
			siteId: '',
			hpNo: '',
			pwd: '',
			userNm: '',
			oriSiteId: '',
			oriUserRoleCd: '',
			userRoleCd: null,
			userMoblPlatfm: null,
			userMoblTokn: null,
			delYn: '',
			roleGrpCds: [],
		},
		detailsOptionsWithoutManager: {},
		// errorUserId: '',
		isUserIdChecked: false,
		itHasManager: false,
		isValidHpno: false,
		curUserId: '',
		errorHpNo: '',
		errorSiteId: '',
		maxLength,
		debug: false,
		isSiteDisabled: false,
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('userPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });
		// grid 출력용
		this.pageParam.userRoleCdOptions.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		this.detailsOptions = {
			companyOptions: this.pageParam.companyOptions,
			roleGroupCodeOptions: this.pageParam.roleGroupCodeOptions,
			delYn: commonCodeList.filter(item => item.grpCd == 'del_yn'),
			userRoleCdOptions: this.pageParam.userRoleCdOptions,
			userMoblPlatfmOptions: this.pageParam.userMoblPlatfmOptions,
			siteOptions: this.pageParam.siteOptions,
		};
		//기본 현장 세팅
		this.searchVM.siteNm = this.detailsOptions.siteOptions.filter(e => e.siteId == this.pageParam.loginUserSiteId)[0].siteNm;
		this.searchOptions = {
			delYn: this.detailsOptions.delYn,
		};
		this.detailsOptionsWithoutManager = this.detailsOptions.userRoleCdOptions.slice(1, this.detailsOptions.userRoleCdOptions.length);
		this.gridColumns.filter(item => item.field == 'userRoleCd')[0].mapData = this.detailsOptions.userRoleCdOptions;
		this.gridColumns.filter(item => item.field == 'delYn')[0].mapData = this.detailsOptions.delYn;
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.search();
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			// return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
			return this.detailsItem.userRoleCd != null && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				if (this.detailsItem.isChangePwd) {
					rule = $.extend(true /*deep*/, { pwd: 'required|min:4|max:20' }, detailsValidationRule.edit);
				} else {
					rule = detailsValidationRule.edit;
				}
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		isEditMode() {
			let userIdField = document.getElementById('userIdField');
			if (this.isEditMode) {
				userIdField.classList.remove('col-lg-9');
				userIdField.style = '';
			} else {
				userIdField.classList.add('col-lg-9');
				// userIdField.style.width = '18vw';
				// userIdField.style.marginLeft = '1.3vw';
				userIdField.style.marginLeft = '24px';
			}
		},
		'detailsItem.userRoleCd'() {
			// 검사하지 않으면 추가나 수정 버튼 클릭 후 닫기 버튼 클릭 시 닫기버튼이 제대로 동작하지 않음
			if (!_.isEmpty(this.detailsItem)) {
				// 본사관리자면 현장 선택하지 않음, 아니면 현장 선택
				if (this.detailsItem.userRoleCd === '00') {
					this.detailsItem.isSite = false;
					this.detailsItem.siteId = null; // 본사관리자면 siteId를 null로 변경
					this.errorSiteId = '';
				} else {
					this.detailsItem.isSite = true;
					this.errorSiteId = '';
				}
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		hpNoDataChk(data, e) {
			let target = e.target;
			let value = target.value;

			value = this.$jarvisExtention.inputDataController.onlyNumLimitLen(value, maxLength.hpNo);

			this.detailsItem[data] = value;
			target.value = value;
		},
		userIdDataChk(data, e) {
			let target = e.target;
			let value = target.value;

			value = this.$jarvisExtention.inputDataController.onlyEngNum(value);

			this.detailsItem[data] = value;
			target.value = value;
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.$nextTick(function () {
				this.loadGrid();
			});
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '사용자관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.$axios
					.post(this.apiUrl.detailsApi, { userId: selectedRowItem.userId })
					.then(
						function (response) {
							this.errorUserId = '';
							this.errorHpNo = '';
							this.errorSiteId = '';
							this.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
							// response.data.pwd = null; // comment: 애초에 응답 받을 때부터 null을 return 하도록
							this.detailsItem = $.extend(true /*deep*/, {}, response.data);
							if (this.detailsItem.userRoleCd !== '00') {
								this.detailsItem.isSite = true;
							}
							this.isUserIdChecked = false;
							setTimeout(() => this.focusFirstInput('firstInputEdit'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
			// this.errorUserId = '';
			this.errorHpNo = '';
			this.errorSiteId = '';
		},
		//데이터 추가 시작
		startCreate() {
			this.errorUserId = '';
			this.errorHpNo = '';
			// this.errorSiteId = '';
			this.detailsItemOriginal = {};
			this.detailsItem = {
				userId: '',
				companyId: '',
				siteId: '',
				hpNo: '',
				pwd: '',
				userNm: '',
				oriSiteId: '',
				oriUserRoleCd: '',
				userRoleCd: '',
				userMoblPlatfm: null,
				userMoblTokn: null,
				delYn: '',
				roleGrpCds: [],
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
			this.checkCurUserId();
		},
		//keyup으로 현재 유저아이디와 중복체크했을 때의 아이디를 비교
		checkCurUserId() {
			this.$nextTick(function () {
				var btn = document.getElementById('duplicationCheckBtn');
				if (this.curUserId == '' || (this.curUserId != '' && this.curUserId != this.detailsItem.userId)) {
					btn.classList.remove('btn-secondary');
					btn.classList.add('btn-primary');
					this.isUserIdChecked = false;
				} else {
					btn.classList.remove('btn-primary');
					btn.classList.add('btn-secondary');
					this.isUserIdChecked = true;
				}
			});
		},
		//사용자ID 중복확인
		async duplicateUserIdCheck() {
			const cursor = this;
			let regEx = /^[A-Za-z0-9]{0,30}$/;
			if (regEx.test(this.detailsItem.userId) == false) {
				return;
			}
			if (this.detailsItem.userId == '' || this.detailsItem.userId == null) {
				alert('사용할 ID를 입력해주세요');
				return;
			}
			var btn = document.getElementById('duplicationCheckBtn');
			await this.$axios
				.post(this.apiUrl.inqCheckUserIdApi, { userId: this.detailsItem.userId })
				.then(
					function (response) {
						if (parseInt(response.data) > 0) {
							// this.errorUserId = 'dupUserId';
							alert('ID가 중복되었습니다. 다른 ID를 입력해주세요.');
							cursor.isUserIdChecked = false;
							cursor.detailsItem.userId = '';
							btn.classList.add('btn-primary');
							btn.classList.remove('btn-secondary');
						} else {
							alert('사용할 수 있는 ID 입니다.');
							cursor.isUserIdChecked = true;
							btn.classList.add('btn-secondary');
							btn.classList.remove('btn-primary');
							this.curUserId = this.detailsItem.userId;
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		//휴대폰번호 중복확인
		async duplicateHpNoCheck() {
			await this.$axios
				.post(this.apiUrl.inqCheckHpNoApi, { hpNo: this.detailsItem.hpNo })
				.then(
					function (response) {
						if (parseInt(response.data) > 0) {
							this.isValidHpno = false;
						} else {
							this.isValidHpno = true;
						}
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		checkSiteAndCompanyHaveManager() {
			const closure = this;
			return this.$axios
				.post(this.apiUrl.inqCheckCompanyManagerApi, { companyId: this.detailsItem.companyId, siteId: this.detailsItem.siteId })
				.then(function (response) {
					if (response.data > 0) {
						closure.itHasManager = true;
					} else {
						closure.itHasManager = false;
					}
				});
		},
		//저장 api 호출
		executeCreate() {
			const closure = this;
			this.$axios
				.post(this.apiUrl.insUserApi, this.detailsItem)
				.then(
					function () {
						closure.isUserIdChecked = false;
						closure.curUserId = '';
						closure.loadGrid();
					}.bind(closure),
				)
				.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
		},
		//신규저장
		async createData() {
			const closure = this;
			await this.duplicateHpNoCheck();
			this.checkSiteId(this.detailsItem.siteId);
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					return;
				}
				if (closure.errorSiteId.length != 0) {
					return;
				}
				if (closure.isUserIdChecked == false) {
					alert('ID를 중복 체크해주세요.');
					return;
				}
				if (closure.isValidHpno == false) {
					alert('핸드폰 번호가 중복되었습니다');
					closure.detailsItem.hpNo = '';
					return;
				}
				// 해당 업체와 현장에 업체책임자가 있는지 조회한다.
				if (closure.detailsItem.userRoleCd == '02') {
					closure
						.checkSiteAndCompanyHaveManager()
						.then(
							function () {
								if (closure.itHasManager == true) {
									closure.detailsItem.userRoleCd = '';
									alert('같은 현장에 이미 등록된 업체책임자가 존재합니다. 업체 책임자는 1명만 등록 가능합니다.');
									return;
								} else {
									closure.executeCreate();
								}
							}.bind(closure),
						)
						.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
				} else {
					closure.executeCreate();
				}
			});
		},
		//updateApi 호출
		executeUpdate() {
			const closure = this;
			this.$axios
				.post(this.apiUrl.updUserApi, this.detailsItem)
				.then(
					function () {
						closure.loadGrid();
					}.bind(closure),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		//수정저장
		updateData() {
			// .bind(this),
			const closure = this;
			this.$validator.validateAll().then(success => {
				if (closure.detailsItem.isSite) {
					if (closure.detailsItem.siteId == null || closure.detailsItem.siteId.length == 0) {
						closure.errorSiteId = 'reqSiteId';
					}
				}
				if (closure.errorSiteId.length !== 0) {
					return;
				}

				if (success == false) {
					return;
				}

				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					// 기존 site Id와 userRoleCd를 저장
					closure.detailsItem.oriSiteId = closure.detailsItemOriginal.siteId;
					closure.detailsItem.oriUserRoleCd = closure.detailsItemOriginal.userRoleCd;
					let oriCompanyId = closure.detailsItemOriginal.companyId;

					if (
						closure.detailsItem.userRoleCd == '02' &&
						(closure.detailsItem.oriUserRoleCd != '02' ||
							closure.detailsItem.oriSiteId != closure.detailsItem.siteId ||
							oriCompanyId != closure.detailsItem.companyId)
					) {
						this.checkSiteAndCompanyHaveManager()
							.then(function () {
								if (closure.itHasManager == true) {
									closure.detailsItem.userRoleCd = '';
									alert('같은 현장에 이미 등록된 업체책임자가 존재합니다. 업체 책임자는 1명만 등록 가능합니다.');
									return;
								} else {
									closure.executeUpdate();
								}
							})
							.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
					} else {
						closure.executeUpdate();
					}
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(this.apiUrl.delUserApi, this.detailsItem)
				.then(
					function (response) {
						response;
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		//check box event
		checkChangePwd() {
			if (this.detailsItem.pwd == '') {
				this.detailsItem.pwd = null;
			} else {
				this.detailsItem.pwd = '';
			}
		},
		checkSiteId(siteId) {
			this.errorSiteId = '';
			if (this.detailsItem.isSite) {
				if (siteId.length == 0) {
					this.errorSiteId = 'reqSiteId';
				}
			}
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
.required {
	color: #d84315;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}

.itemWrapper {
	display: flex;
	margin-bottom: 20px;
}
.itemWrapper > label {
	margin-top: 7px;
}

.itemWrapper > div > label {
	margin-top: 7px;
	margin-bottom: 7px;
	display: block;
	color: #d84315;
	position: relative;
	padding-left: 26px;
	font-size: 13px;
	display: none;
}

.itemWrapper .msg:before {
	content: '\ed63';
	font-family: 'icomoon';
	font-size: 16px;
	position: absolute;
	top: -2px;
	left: 0;
}

/* .itemWrapper.dupUserId > label,
.itemWrapper.reqUserId > label,
.itemWrapper.patUserId > label {
	color: #d84315;
}
.itemWrapper.dupUserId .msg,
.itemWrapper.reqUserId .msg,
.itemWrapper.patUserId .msg {
	text-align: center;
	display: block;
}
.itemWrapper.dupUserId input,
.itemWrapper.reqUserId input,
.itemWrapper.patUserId input {
	border: 1px solid #d84315;
}
.itemWrapper.dupUserId .msg:after {
	content: '이미 등록된 사용자ID입니다.';
}
.itemWrapper.reqUserId .msg:after {
	content: '사용자ID는 필수 정보입니다.';
}
.itemWrapper.patUserId .msg:after {
	content: '숫자 또는 영어만 입력 가능합니다. (최대 30자)';
} */

.itemWrapper.dupHpNo > label,
.itemWrapper.reqHpNo > label,
.itemWrapper.patHpNo > label {
	color: #d84315;
}
.itemWrapper.dupHpNo .msg,
.itemWrapper.reqHpNo .msg,
.itemWrapper.patHpNo .msg {
	text-align: center;
	display: block;
}
.itemWrapper.dupHpNo input,
.itemWrapper.reqHpNo input,
.itemWrapper.patHpNo input {
	border: 1px solid #d84315;
}
.itemWrapper.dupHpNo .msg:after {
	content: '이미 등록된 휴대폰번호입니다.';
}
.itemWrapper.reqHpNo .msg:after {
	content: '휴대폰번호는 필수 정보입니다.';
}
.itemWrapper.patHpNo .msg:after {
	content: '올바르지 않은 정보입니다. 숫자만 입력해주세요. (010123445678)';
}

.itemWrapper.reqCompanyId > label,
.itemWrapper.reqSiteId > label {
	color: #d84315;
}

.itemWrapper.reqCompanyId .msg,
.itemWrapper.reqSiteId .msg {
	text-align: center;
	display: block;
}

/* .itemWrapper.reqCompanyId .select2-selection--single,
.itemWrapper.reqSiteId .select2-selection--single {
	border: 1px solid #d84315;
} */

.itemWrapper.reqSiteId .select-container {
	border: 1px solid #d84315;
	border-radius: 3px;
}

.itemWrapper.reqCompanyId .msg:after {
	content: '업체ID는 필수 정보입니다.';
}

.itemWrapper.reqSiteId .msg:after {
	content: '현장ID는 필수 정보입니다.';
}
.col-md-3 {
	width: 300px;
}
label {
	text-align: right;
	padding-right: 15px;
}

#duplicationCheckBtn {
	display: inline;
}
</style>
